var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"dashboard","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('base-material-chart-card',{attrs:{"data":_vm.metricsProfiles.data,"options":_vm.metricsProfiles.options,"responsive-options":_vm.metricsProfiles.responsiveOptions,"color":"blue","hover-reveal":"","type":"Bar","is-loading":_vm.loading['metrics-profiles']},scopedSlots:_vm._u([{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"light","icon":""},on:{"click":function($event){return _vm.update('metrics-profiles')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"light"}},[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v("Mettre à jour")])])]},proxy:true},{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-calendar-clock ")]),_c('span',{staticClass:"caption grey--text font-weight-light"},[_vm._v("Dernières 24 Heures. Seules les heures avec données sont affichées")])]},proxy:true}])},[_c('h4',{staticClass:"card-title font-weight-light mt-2 ml-2"},[_vm._v(" Statistiques des profils ")]),_c('p',{staticClass:"d-inline-flex font-weight-light ml-2 mt-1"},[_vm._v(" Profils mis à jour / Nouveaux profils ajoutés ")])])],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('base-material-chart-card',{attrs:{"data":_vm.updatedProfiles.data,"options":_vm.updatedProfiles.options,"hover-reveal":"","color":"secondary","type":"Line","is-loading":_vm.loading['updated-profiles']},scopedSlots:_vm._u([{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"light","icon":""},on:{"click":function($event){return _vm.update('updated-profiles')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"light"}},[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v("Mettre à jour")])])]},proxy:true},{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-calendar ")]),_c('span',{staticClass:"caption grey--text font-weight-light"},[_vm._v("Durant la dernière semaine ")])]},proxy:true}])},[_c('h3',{staticClass:"card-title font-weight-light mt-2 ml-2"},[_vm._v(" Profils mis à jour ")]),_c('p',{staticClass:"d-inline-flex font-weight-light ml-2 mt-1"},[_vm._v(" Nombre de profils mis à jour et ajoutés par jour ")])])],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('base-material-chart-card',{attrs:{"data":_vm.usedCredits.data,"options":_vm.usedCredits.options,"color":"success","hover-reveal":"","type":"Line","is-loading":_vm.loading['used-credits']},scopedSlots:_vm._u([{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"light","icon":""},on:{"click":function($event){return _vm.update('used-credits')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"light"}},[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v("Mettre à jour")])])]},proxy:true},{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-calendar ")]),_c('span',{staticClass:"caption grey--text font-weight-light"},[_vm._v("Durant la dernière semaine")])]},proxy:true}])},[_c('h4',{staticClass:"card-title font-weight-light mt-2 ml-2"},[_vm._v(" Crédits utilisés ")]),_c('p',{staticClass:"d-inline-flex font-weight-light ml-2 mt-1"},[_vm._v(" Nombre de crédits utilisés par jour ")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-material-stats-card',{attrs:{"hover-reveal":"","color":"blue","icon":"mdi-account-search","title":"Profils analysés","value":_vm.numAnalyzedProfiles,"sub-icon":"mdi-calendar-clock","sub-text":"Dernières 24 Heures","is-loading":_vm.loading['num-analyzed-profiles']},scopedSlots:_vm._u([{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"light","icon":""},on:{"click":function($event){return _vm.update('num-analyzed-profiles')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"light"}},[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v("Mettre à jour")])])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-material-stats-card',{attrs:{"hover-reveal":"","color":"secondary","icon":"mdi-account-convert","title":"Profils mis à jour","value":_vm.numUpdatedProfiles,"sub-icon":"mdi-calendar-clock","sub-text":"Dernières 24 Heures","is-loading":_vm.loading['num-updated-profiles']},scopedSlots:_vm._u([{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"light","icon":""},on:{"click":function($event){return _vm.update('num-updated-profiles')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"light"}},[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v("Mettre à jour")])])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-material-stats-card',{attrs:{"hover-reveal":"","color":"orange","icon":"mdi-account-multiple-plus ","title":"Profils ajoutés","value":_vm.numAddedProfiles,"sub-icon":"mdi-calendar-clock","sub-text":"Dernières 24 Heures","is-loading":_vm.loading['num-added-profiles']},scopedSlots:_vm._u([{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"light","icon":""},on:{"click":function($event){return _vm.update('num-added-profiles')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"light"}},[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v("Mettre à jour")])])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-material-stats-card',{attrs:{"hover-reveal":"","color":"success","icon":"mdi-cash-multiple","title":"Crédits utilisés","value":_vm.numUsedCredits,"sub-icon":"mdi-calendar","sub-text":"Dernièrs 30 jours","is-loading":_vm.loading['num-used-credits']},scopedSlots:_vm._u([{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"light","icon":""},on:{"click":function($event){return _vm.update('num-used-credits')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"light"}},[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v("Mettre à jour")])])]},proxy:true}])})],1),_c('v-col',[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"hover-reveal":"","color":"primary"},scopedSlots:_vm._u([{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"light","icon":""},on:{"click":function($event){return _vm.update('metrics-scraper', _vm.optionsMetricsScraper)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"light"}},[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v("Mettre à jour")])])]},proxy:true},{key:"heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" Statistiques du Scraper ")]),_c('div',{staticClass:"subtitle-1 font-weight-light"},[_vm._v(" Lancement du Scraper ")])]},proxy:true}])},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.metricsScraper,"loading":_vm.loading['metrics-scraper'],"loading-text":"Chargement... veuillez patienter","no-data-text":"Sans résultats","options":_vm.optionsMetricsScraper,"server-items-length":_vm.totalMetricsScraper,"multi-sort":true,"footer-props":{
              itemsPerPageText: 'Lignes par page:',
              itemsPerPageOptions: [10,100,500],
            }},on:{"update:options":function($event){_vm.optionsMetricsScraper=$event}},scopedSlots:_vm._u([{key:"item.startAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.startAt).calendar())+" ")]}},{key:"item.endAt",fn:function(ref){
            var item = ref.item;
return [(item.endAt)?_c('span',[_vm._v(" "+_vm._s(_vm.moment(item.endAt).calendar())+" ")]):_c('v-progress-linear',{attrs:{"indeterminate":"","rounded":"","height":"6"}})]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [(item.error)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.error.type}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.errorIcon(item.error))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.errorRender(item.error)))])]):_vm._e()]}}])})],1)],1)],1)],1),_c('base-material-overlay-alert',{attrs:{"type":_vm.alertType},model:{value:(_vm.alertError),callback:function ($$v) {_vm.alertError=$$v},expression:"alertError"}},[_vm._v(" "+_vm._s(_vm.alertErrorText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }