<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="metricsProfiles.data"
          :options="metricsProfiles.options"
          :responsive-options="metricsProfiles.responsiveOptions"
          color="blue"
          hover-reveal
          type="Bar"
          :is-loading="loading['metrics-profiles']"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="light"
                  icon
                  v-on="on"
                  @click="update('metrics-profiles')"
                >
                  <v-icon
                    color="light"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Mettre à jour</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            Statistiques des profils
          </h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Profils mis à jour / Nouveaux profils ajoutés
          </p>

          <template
            v-slot:actions
          >
            <v-icon
              class="mr-1"
              small
            >
              mdi-calendar-clock
            </v-icon>
            <span class="caption grey--text font-weight-light">Dernières 24 Heures. Seules les heures avec données sont affichées</span>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="updatedProfiles.data"
          :options="updatedProfiles.options"
          hover-reveal
          color="secondary"
          type="Line"
          :is-loading="loading['updated-profiles']"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="light"
                  icon
                  v-on="on"
                  @click="update('updated-profiles')"
                >
                  <v-icon
                    color="light"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Mettre à jour</span>
            </v-tooltip>
          </template>

          <h3 class="card-title font-weight-light mt-2 ml-2">
            Profils mis à jour
          </h3>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Nombre de profils mis à jour et ajoutés par jour
          </p>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-calendar
            </v-icon>
            <span class="caption grey--text font-weight-light">Durant la dernière semaine
            </span>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="usedCredits.data"
          :options="usedCredits.options"
          color="success"
          hover-reveal
          type="Line"
          :is-loading="loading['used-credits']"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="light"
                  icon
                  v-on="on"
                  @click="update('used-credits')"
                >
                  <v-icon
                    color="light"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Mettre à jour</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            Crédits utilisés
          </h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Nombre de crédits utilisés par jour
          </p>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-calendar
            </v-icon>
            <span class="caption grey--text font-weight-light">Durant la dernière semaine</span>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          hover-reveal
          color="blue"
          icon="mdi-account-search"
          title="Profils analysés"
          :value="numAnalyzedProfiles"
          sub-icon="mdi-calendar-clock"
          sub-text="Dernières 24 Heures"
          :is-loading="loading['num-analyzed-profiles']"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="light"
                  icon
                  v-on="on"
                  @click="update('num-analyzed-profiles')"
                >
                  <v-icon
                    color="light"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Mettre à jour</span>
            </v-tooltip>
          </template>
        </base-material-stats-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          hover-reveal
          color="secondary"
          icon="mdi-account-convert"
          title="Profils mis à jour"
          :value="numUpdatedProfiles"
          sub-icon="mdi-calendar-clock"
          sub-text="Dernières 24 Heures"
          :is-loading="loading['num-updated-profiles']"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="light"
                  icon
                  v-on="on"
                  @click="update('num-updated-profiles')"
                >
                  <v-icon
                    color="light"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Mettre à jour</span>
            </v-tooltip>
          </template>
        </base-material-stats-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          hover-reveal
          color="orange"
          icon="mdi-account-multiple-plus "
          title="Profils ajoutés"
          :value="numAddedProfiles"
          sub-icon="mdi-calendar-clock"
          sub-text="Dernières 24 Heures"
          :is-loading="loading['num-added-profiles']"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="light"
                  icon
                  v-on="on"
                  @click="update('num-added-profiles')"
                >
                  <v-icon
                    color="light"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Mettre à jour</span>
            </v-tooltip>
          </template>
        </base-material-stats-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          hover-reveal
          color="success"
          icon="mdi-cash-multiple"
          title="Crédits utilisés"
          :value="numUsedCredits"
          sub-icon="mdi-calendar"
          sub-text="Dernièrs 30 jours"
          :is-loading="loading['num-used-credits']"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="light"
                  icon
                  v-on="on"
                  @click="update('num-used-credits')"
                >
                  <v-icon
                    color="light"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Mettre à jour</span>
            </v-tooltip>
          </template>
        </base-material-stats-card>
      </v-col>

      <v-col>
        <base-material-card
          hover-reveal
          color="primary"
          class="px-5 py-3"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="light"
                  icon
                  v-on="on"
                  @click="update('metrics-scraper', optionsMetricsScraper)"
                >
                  <v-icon
                    color="light"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Mettre à jour</span>
            </v-tooltip>
          </template>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Statistiques du Scraper
            </div>

            <div class="subtitle-1 font-weight-light">
              Lancement du Scraper
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="metricsScraper"
              :loading="loading['metrics-scraper']"
              loading-text="Chargement... veuillez patienter"
              no-data-text="Sans résultats"
              :options.sync="optionsMetricsScraper"
              :server-items-length="totalMetricsScraper"
              :multi-sort="true"
              :footer-props="{
                itemsPerPageText: 'Lignes par page:',
                itemsPerPageOptions: [10,100,500],
              }"
            >
              <template v-slot:item.startAt="{ item }">
                {{ moment(item.startAt).calendar() }}
              </template>
              <template v-slot:item.endAt="{ item }">
                <span v-if="item.endAt">
                  {{ moment(item.endAt).calendar() }}
                </span>
                <v-progress-linear
                  v-else
                  indeterminate
                  rounded
                  height="6"
                />
              </template>
              <template v-slot:item.status="{ item }">
                <v-tooltip
                  v-if="item.error"
                  bottom
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-icon
                      v-bind="attrs"
                      :color="item.error.type"
                      v-on="on"
                    >
                      {{ errorIcon(item.error) }}
                    </v-icon>
                  </template>

                  <span>{{ errorRender(item.error) }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <base-material-overlay-alert
      v-model="alertError"
      :type="alertType"
    >
      {{ alertErrorText }}
    </base-material-overlay-alert>
  </v-container>
</template>

<script>
  import DashboardService from '@/services/metrics.service'
  import { eventBus } from '@/main.js'

  import moment from 'moment'
  moment.locale('fr')

  /**
   * @vue-prop {Number} initialCounter - Initial counter's value
   * @vue-prop {Number} [step=1] - Step
   * @vue-data {Number} counter - Current counter's value
   * @vue-computed {String} message
   * @vue-event {Number} increment - Emit counter's value after increment
   * @vue-event {Number} decrement - Emit counter's value after decrement
   */
  export default {
    name: 'DashboardDashboard',
    data () {
      return {
        optionsMetricsScraper: {},
        totalMetricsScraper: 0,
        loading: {
          'metrics-profiles': false,
          'used-credits': false,
          'updated-profiles': false,
          'num-analyzed-profiles': false,
          'num-used-credits': false,
          'num-updated-profiles': false,
          'num-added-profiles': false,
          'metrics-scraper': false,
        },
        alertType: 'error',
        alertError: false,
        alertErrorText: 'Une erreur est survenue',
        numAnalyzedProfiles: '0',
        numUsedCredits: '0',
        numUpdatedProfiles: '0',
        numAddedProfiles: '0',
        usedCreditsData: null,
        usedCredits: {
          data: {},
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            chartPadding: {
              top: 20,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        updatedProfilesData: null,
        updatedProfiles: {
          data: {},
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            chartPadding: {
              top: 20,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        metricsProfilesData: null,
        metricsProfiles: {
          data: {},
          options: {
            axisX: {
              showGrid: false,
            },
            low: 0,
            chartPadding: {
              top: 20,
              right: 5,
              bottom: 0,
              left: 0,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
        headers: [
          {
            sortable: true,
            text: 'Heure de début',
            value: 'startAt',
          },
          {
            sortable: true,
            text: 'Heure de fin',
            value: 'endAt',
            align: 'right',
          },
          {
            sortable: true,
            text: 'Profils analysés',
            value: 'analyzed',
            align: 'right',
          },
          {
            sortable: true,
            text: 'Profils mis à jour',
            value: 'updated',
            align: 'right',
          },
          {
            sortable: true,
            text: 'Profils ajoutés',
            value: 'added',
            align: 'right',
          },
          {
            sortable: true,
            text: 'CV téléchargés',
            value: 'downloaded',
            align: 'right',
          },
          {
            sortable: true,
            text: 'Crédits utilisés',
            value: 'creditsUsed',
            align: 'right',
          },
          {
            text: '',
            value: 'status',
            sortable: false,
          },
        ],
        metricsScraper: [],
      }
    },
    watch: {
      optionsMetricsScraper: {
        handler () {
          this.update('metrics-scraper', this.optionsMetricsScraper)
        },
        deep: true,
      },
      metricsProfilesData (newData, oldDate) {
        this.metricsProfiles.data = newData
      },
      updatedProfilesData (newData, oldDate) {
        this.updatedProfiles.data = newData
      },
      usedCreditsData (newData, oldDate) {
        this.usedCredits.data = newData
      },
    },
    mounted () {
      this.updateAll()
    },
    created () {
      eventBus.$on('DashboardUpdateAll', () => {
        this.updateAll()
      })
    },
    methods: {
      moment,
      errorIcon (error) {
        switch (error.type) {
          case 'error':
            return 'mdi-alert-octagon'
          case 'warning':
            return 'mdi-alert'
          case 'info':
            return 'mdi-information'
        }
      },
      errorRender (error) {
        let errorText = ''
        if (typeof error.name !== 'undefined') {
          errorText += error.name
        }
        if (typeof error.message !== 'undefined') {
          if (errorText !== '') {
            errorText += ': '
          }
          errorText += error.message
        }
        return errorText
      },
      showAlert (type, text) {
        this.alertType = type
        this.alertErrorText = text
        this.alertError = true
      },
      updateAll () {
        this.update('all')
      },
      update (type, options) {
        this.updateLoading(type, true)
        return DashboardService.getMetrics(type, options).then(
          response => {
            this.updateLoading(type, false)
            for (const data of response.data) {
              this[data.key] = data.value
            }
          },
          error => {
            const message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString()
            // console.log(error)
            const alertType = (error.response && error.response.data && error.response.data.type) || 'error'
            this.showAlert(alertType, message)
            this.updateLoading(type, false)
          },
        )
      },
      updateLoading (type, status) {
        if (type === 'all') {
          const self = this
          Object.keys(this.loading).forEach(function (key) {
            self.loading[key] = status
          })
        } else {
          this.loading[type] = status
        }
      },
    },
  }
</script>

<style lang="sass">
  .v-tooltip__content
    white-space: pre
  .v-card--material-chart
    .v-card--material__heading
      .ct-series-b .ct-bar,
      .ct-series-b .ct-line,
      .ct-series-b .ct-point,
      .ct-series-b .ct-slice-donut
          stroke: #FFB74D

</style>
