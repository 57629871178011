import authHeader from './auth-header'

import axios from 'axios'

const $http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
})

const API_URL = '/metrics/'

class DashboardService {
  /**
   * Get metrics based on type
   * Types:
   *    all
   *    metrics-profiles
   *    used-credits
   *    updated-profiles
   *    metrics-scraper
   *    num-analyzed-pro
   *    num-used-credits
   *    num-updated-prof
   *    num-added-profil
   *
   * @param {String} type
   * @param {Object} options
   */
  getMetrics (type, options) {
    return $http.get(API_URL + type, { headers: authHeader(), params: options })
  }
}

export default new DashboardService()
